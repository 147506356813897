<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'消防设备'"
    >
      <div class="search" slot="tool">
        <el-input
          clearable
          placeholder="根据设备名称查询"
          v-model="searchQuery.deviceName"
          class="searchInput"
        >
          <template slot="prepend">设备名称</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/fireFacility",
      searchVal: "",
      jobList: [],
      searchQuery: { deviceName: "" },
      props: [
        {
          label: "设备名称",
          prop: "deviceName",
          align: "center",
          width:"250px",
        },

        {
          label: "设备编号",
          prop: "deviceNum",
          align: "center",
          width:"200px",
        },
        {
          label: "位置",
          prop: "locationName",
          align: "center",
        },
        {
          label: "状态",
          prop: "deviceStatus",
          align: "center",
          type: "colorText",
          width:"100px",
          formatter: function(row) {
            const statusMap = {
              0: { color: "#67C23A", text: "正常" },
              1: { color: "#F56C6C", text: "异常" },
            };
            return statusMap[row.deviceStatus];
          },
        },
        {
          label: "备注",
          prop: "issue",
          align: "center",
        },
      ],

      formProps: [
        {
          label: "设备名称",
          prop: "deviceName",
          type: "input",
        },

        {
          label: "设备编号",
          prop: "deviceNum",
          type: "input",
        },
        {
          label: "位置",
          prop: "locationName",
          type: "input",
        },
        {
          label: "设备状态",
          prop: "deviceStatus",
          type: "select",
          selectData: [
            { label: "正常", value: 0 },
            { label: "异常", value: 1 },
          ],
        },
        {
          label: "备注",
          prop: "issue",
          type: "input",
        },
      ],
    };
  },
  created() {
    // this.getJobList();
  },
  methods: {
    async getJobList() {
      await api.dictionary.getAllList({ type: "商品分类" }).then((res) => {
        this.formProps[2].selectData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
